.register {
  /* background: linear-gradient(to right, #434343 0%, black 100%); */
  background: black;
  min-height: 100vh;
  /* height: 100vh; */
  /* overflow: auto; */
  box-sizing: border-box;
  /* padding-top: 50px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.registertitle {
  color: maroon;
  font-size: 2em;
  font-weight: 600;
  text-align: center;
  margin: 5px;
  margin-top: 60px;
}

.cover {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: calc(100vh - 150px);
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.17);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.6px);
  -webkit-backdrop-filter: blur(5.6px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  overflow: hidden;
}

.registertitle2 {
  text-align: center;
  font-size: 35px;
  font-weight: 500;
  color: white;
}

.registertext {
  text-align: center;
  color: #fff;
  margin-bottom: 20px;
}

.details {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 10px;
  justify-content: space-evenly;
  align-items: center;
  overflow: auto;
}

.submitbut {
  text-align: center;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  width: 100px;
  height: 35px;
  background-image: linear-gradient(to top, #d8d9db 0%, #fff 80%, #fdfdfd 100%);
  border-radius: 30px;
  border: 1px solid #8f9092;
  transition: all 0.2s ease;
  font-size: 14px;
  font-weight: 600;
  color: #606060;
  text-shadow: 0 1px #fff;
}

.field {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px;
  width: 30%;
  min-width: 200px;
  max-width: 300px;
}

.inputbox {
  border: none;
  border-bottom: 2px solid white;
  width: 100%;
  height: 30px;
  outline: none;
  background-color: transparent;
  color: white;
  font-size: 14px;
}

.option {
  background-color: rgb(87, 87, 87);
}

.verifybutton {
  margin: 8px 0px 10px 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  width: 100px;
  height: 25px;
  background-image: linear-gradient(to top, #d8d9db 0%, #fff 80%, #fdfdfd 100%);
  border-radius: 30px;
  border: 1px solid #8f9092;
  transition: all 0.2s ease;
  font-size: 14px;
  font-weight: 600;
  color: #606060;
  text-shadow: 0 1px #fff;
}
