.card {
  background: linear-gradient(0deg, transparent 10.6%, rgb(255, 0, 0) 97.7%);
  font-family: "Montserrat", sans-serif;
  width: 200px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}

.card .content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: var(--cardColor);
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}

.text {
  margin-top: 10px;
  text-align: center;
  color: var(--headingColor);
  font-size: 1.2rem;
  font-weight: 600;
}

.content .img img {
  width: 130px;
  height: 130px;
}

.cardInner {
  background-color: rgba(0, 0, 0, 0.6);
  color: var(--headingColor);
  position: absolute;
  bottom: -64px;
  left: -30px;
  z-index: 1;
  width: 200px;
  height: 0px;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
  padding: 2rem 1.3rem 2rem 2rem;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}

.card:hover .cardInner {
  height: 100%;
}

.cardInner h2 {
  margin-top: 1rem;
  font-size: 1.3em;
  text-align: center;
}

.cardInner p {
  margin-top: 1rem;
  font-size: 1.1rem;
  text-align: center;
}
