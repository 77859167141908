.navbar {
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: black;
  color: var(--headingColor);
  position: fixed;
  z-index: 10;
}

.left {
  /* width: 250px; */
}

.left img {
  width: 30px;
  height: 45px;
}

.left a {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.left a {
  text-decoration: none;
  color: var(--headingColor);
}
.left h1 {
  font-size: 1.8em;
  margin: 0px;
}

.rightOuterDiv {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  /* width: 70%; */
}

.right {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  list-style-type: none;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  /* width: 100%; */
}

.right li {
  margin-left: 10px;
}

.right a {
  text-decoration: none;
  font-size: 20px;
  color: var(--headingColor);
  transition: 0.1s ease-in;
}

.right a:hover {
  color: var(--secondaryColor);
  font-weight: 700;
}
.loginBtn {
  border: 2px solid var(--headingColor);
  box-sizing: border-box;
  padding: 5px;
  border-radius: 8px;
  transition: 0.1s;
  margin: 10px;
  cursor: pointer;
  color: var(--headingColor);
}

.rightOuterDiv a {
  text-decoration: none;
}

.loginBtn:hover {
  border: 2px solid var(--secondaryColor);
  color: var(--secondaryColor);
}

.loginBtn:active {
  transform: translateY(2px);
}

@media screen and (max-width: 930px) {
  .right {
    display: none;
  }
}

@media screen and (max-width: 300px) {
  .left h1 {
    font-size: 1.4em;
  }
}
