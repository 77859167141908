@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap");
.popupContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed; 
    top: 0; 
    left: 0; 
    height: 100vh;
    width: 100vw;
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000; 
}
.text h2{
 color: #ff0000;

}
.text{
 row-gap: 1rem;
 font-family:  "Roboto Condensed", sans-serif;
}
.popupContent {
    background-color: #1a1a1a;
    border-radius: 8px;
    padding: 30px;
    width: 300px;
    text-align: center;
    position: relative;
}

.linkContainer{
    display: flex;
    align-items: center;
    justify-content: space-between; 
    width: 95%;
    row-gap: 1rem;
    font-size: 1.2rem;
    font-size: 20px;
}
.closeButton {
    position: absolute;
    top: 10px;
    right: 15px;
    color: #ff0000;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
}

.popupContent p {
    color: #e0e0e0; 
    margin-bottom: 20px;
}

.JoinBtn {
    background-color: #ff0000;
    color: #ffffff;
    padding: 5px 10px;
    text-decoration: none;
    border-radius: 4px;
    font-weight: bold;
    font-size: 13px;
    transition: background-color 0.3s;
    cursor: pointer;
}

.joinButton:hover {
    background-color: #e60000; 
}
@media (max-width: 768px) {
  .popupContent {
    padding: 20px;
    max-width: 90%;
  }

  .closeButton {
    font-size: 20px;
  }

  .JoinBtn {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .popupContent {
    padding: 10px;
    max-width: 95%;
  }

  .closeButton {
    font-size: 18px;
  }

  .JoinBtn {
    font-size: 12px;
  }
}