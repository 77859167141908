.btn {
  outline: none;
  border: 2px solid var(--headingColor);
  height: 30px;
  box-sizing: border-box;
  padding: 3px;
  min-width: 100px;
  border-radius: 8px;
  background-color: transparent;
  color: var(--headingColor);
  transition: 0.1s;
  margin: 5px;
}

.btn:hover {
  box-shadow: 0px 0px 5px 1px var(--shadowColor);
  /* border: 2px solid var(--); */
}

.btn:active {
  transform: translateY(2px);
}

.btnGroup {
  height: auto;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
