.dashboard {
  height: 100vh;
  /* background: linear-gradient(to right, #434343 0%, black 100%); */
  background: black;
}

.registertitle {
  color: white;
  font-size: 2em;
  font-weight: 600;
  text-align: center;
  margin: 0px;
  padding-top: 80px;
}

.tabs {
  width: 100%;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  /* margin-top: 80px; */
}

.tabs > div {
  display: flex;
  justify-content: center;
}

.tabList {
  width: 100%;
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--headingColor);
}

.tabList li {
  color: var(--secondaryColor);
  font-size: 1.2em;
  font-weight: 800;
  overflow: hidden;
  text-align: center;
  padding: 5px;
  margin: 5px;
}

@media screen and (max-width: 500px) {
  .tabList li {
    font-size: 0.75rem;
  }
}
