@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;700&display=swap");

.card {
  position: relative;
  color: var(--headingColor);
  box-sizing: border-box;
  padding: 15px 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 350px;
  width: 230px;
  margin: 10px;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  overflow: hidden;
}

.curve {
  position: absolute;
  top: -80%;
  border-radius: 50%;
  width: 400px;
  height: 400px;
  background: linear-gradient(45deg, transparent 10.6%, rgb(255, 0, 0) 97.7%);
  z-index: -1;
}

.profile {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  border: 5px solid var(--headingColor);
}

.profilename {
  font-size: 20px;
  margin: 5px;
}

.about {
  font-size: 18px;
  line-height: 1.6;
}

.card a {
  text-decoration: none;
  color: var(--headingColor);
}

.card p {
  margin-top: 0;
}

.socialLinks {
  width: 80%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

/* .card a svg {
  width: 30px;
  height: 30px;
  margin: 0 10px;
  fill: var(--headingColor);
} */

/* .contactInfo {
  font-size: 18px;
}

.contactInfo p {
  margin: 5px 0 0;
} */

/* .card:hover .contactInfo {
  transform: translateY(-120px);
  transition: all 0.2s ease-in-out;
}

.socialLinks {
  margin-top: 20px;
}

.socialLinks a svg:hover {
  fill: var(--descColor);
} */
