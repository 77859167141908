.profilePage {
  border: 1px solid white;
  width: 50%;
  height: calc(100vh - 220px);
  margin-top: 20px;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.12);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.3px);
  -webkit-backdrop-filter: blur(4.3px);
  border: 1px solid rgba(255, 255, 255, 1);
  box-sizing: border-box;
  padding: auto;

  overflow: hidden;
}

.wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

/* INPUT */
.textInput {
  margin: 10px;
  display: flex;
  flex-direction: column;
}

.input {
  outline: none;
  border: none;
  border-bottom: 2px solid var(--headingColor);
  width: 100%;
  height: 30px;
  background-color: transparent;
  caret-color: var(--headingColor);
  color: var(--headingColor);
  z-index: 10;
}

.label {
  color: var(--headingColor);
  height: 30px;
  z-index: -10;
}

@media screen and (max-width: 500px) {
  .profilePage {
    width: 80%;
  }
}
