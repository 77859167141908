.leaderboard {
  border: 1px solid white;
  width: 80%;
  height: calc(100vh - 220px);

  margin-top: 20px;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.12);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.3px);
  -webkit-backdrop-filter: blur(4.3px);
  border: 1px solid rgba(255, 255, 255, 1);
  box-sizing: border-box;
  padding: auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  font-size: 0.9em;
  color: var(--headingColor);
}

.wrapper {
  width: 100%;
  overflow: auto;
}

.table {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
}
.entry {
  box-sizing: border-box;
  padding: 10px;
  border-bottom: 1px solid var(--headingColor);
}

td,
th {
  text-align: center;
  box-sizing: border-box;
  padding: 10px;
}
