.container {
  padding: 0 30px;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: column;
}

.container h1 {
  font-size: 3rem;
  text-align: center;
  margin: 5rem 0 2rem;
  color: var(--headingColor);
}

.container img {
  width: 400px;
  height: 400px;
  margin: 0 auto;
}

.container .expectationImg {
  height: 500px;
}

@media screen and (max-width: 500px) {
  .container h1 {
    font-size: 2rem;
    margin-top: 4rem;
  }

  .container img {
    width: 300px;
    height: 300px;
  }

  .container .expectationImg {
    height: 350px;
  }
}

@media screen and (max-width: 350px) {
  .container {
    padding: 0 15px;
  }
  .container h1 {
    font-size: 1.5rem;
  }

  .container img {
    width: 220px;
    height: 220px;
  }

  .container .expectationImg {
    height: 280px;
  }
}

.cardContainer {
  display: flex;
  gap: 4rem;
  justify-content: center;
  flex-wrap: wrap;
  margin: 50px 0;
}
