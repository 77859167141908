.ideas {
  border: 1px solid white;
  width: 50%;
  height: calc(100vh - 220px);

  margin-top: 20px;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.12);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.3px);
  -webkit-backdrop-filter: blur(4.3px);
  border: 1px solid rgba(255, 255, 255, 1);
  box-sizing: border-box;
  padding: auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* INPUT */
.textInput {
  margin: 10px;
  width: 50%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.input {
  outline: none;
  border: none;
  border-bottom: 2px solid var(--headingColor);
  /* height: 30px; */
  background-color: transparent;
  caret-color: var(--headingColor);
  color: var(--headingColor);
  z-index: 10;
  width: 100%;
  height: 100%;
  resize: none;
}

.label {
  color: var(--headingColor);
  height: 30px;
  z-index: -10;
}

.btn {
  outline: none;
  border: 2px solid var(--headingColor);
  height: 30px;
  box-sizing: border-box;
  padding: 3px;
  min-width: 100px;
  border-radius: 8px;
  background-color: transparent;
  color: var(--headingColor);
  transition: 0.1s;
  margin: 5px;
}

.btn:hover {
  box-shadow: 0px 0px 5px 1px var(--shadowColor);
  /* border: 2px solid var(--); */
}

.btn:active {
  transform: translateY(2px);
}

@media screen and (max-width: 500px) {
  .ideas {
    width: 80%;
  }
}
