.login {
  /* background: linear-gradient(to right, #434343 0%, black 100%); */
  background: black;
  height: 100vh;
  overflow: auto;
}

.logintitle {
  color: maroon;
  font-size: 2em;
  font-weight: 600;
  text-align: center;
}

.cover {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 25%;
  min-width: 230px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 18%;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.17);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.6px);
  -webkit-backdrop-filter: blur(5.6px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.logintitle2 {
  text-align: center;
  font-size: 35px;
  font-weight: 500;
  color: white;
}

.details {
  align-items: center;
}

.loginbut {
  margin: 8px 0px 10px 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  width: 100px;
  height: 35px;
  background-image: linear-gradient(to top, #d8d9db 0%, #fff 80%, #fdfdfd 100%);
  border-radius: 30px;
  border: 1px solid #8f9092;
  transition: all 0.2s ease;
  font-size: 14px;
  font-weight: 600;
  color: #606060;
  text-shadow: 0 1px #fff;
}

.loginbut:hover {
  box-shadow: 0 4px 3px 1px #fcfcfc, 0 6px 8px #d6d7d9, 0 -4px 4px #cecfd1,
    0 -6px 4px #fefefe, inset 0 0 3px 3px #cecfd1;
}

.loginbut:active {
  box-shadow: 0 4px 3px 1px #fcfcfc, 0 6px 8px #d6d7d9, 0 -4px 4px #cecfd1,
    0 -6px 4px #fefefe, inset 0 0 5px 3px #999, inset 0 0 30px #aaa;
}

.loginbut:focus {
  box-shadow: 0 4px 3px 1px #fcfcfc, 0 6px 8px #d6d7d9, 0 -4px 4px #cecfd1,
    0 -6px 4px #fefefe, inset 0 0 5px 3px #999, inset 0 0 30px #aaa;
}

.glogin {
  margin-bottom: 20px;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  color: red;
  transition: all 1000ms;
  font-size: 15px;
  position: relative;
  overflow: hidden;
  outline: 2px solid maroon;
}

.glogin:hover {
  color: #ffffff;
  transform: scale(1.1);
  outline: 2px solid red;
  box-shadow: 4px 5px 17px -4px maroon;
}

.glogin::before {
  content: "";
  position: absolute;
  left: -50px;
  top: 0;
  width: 0;
  height: 100%;
  background-color: maroon;
  transform: skewX(45deg);
  z-index: -1;
  transition: width 1000ms;
}

.glogin:hover::before {
  width: 250%;
}

.signuplink {
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
  color: slategray;
}

.signuptext {
  margin: 5px 0px 10px 52px;
}
